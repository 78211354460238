// import React from 'react';
import React from "react";
import {Link} from "gatsby";

if (typeof window !== 'undefined') {
  window.sessionStorage.setItem("firstLook", "true");
}

let sessionStorageItemHeader;

class Logo extends React.Component {
// eslint-disable-next-line
  componentWillMount() {
    if (typeof window !== 'undefined') {
      sessionStorageItemHeader = window.sessionStorage.getItem("firstLook");
    } else {
      sessionStorageItemHeader = false;
    }
  }

  whichAnimationEvent(){
    var t;
    var el = document.createElement('fakeelement');
    var transitions = {
      'animation':'animationend',
      'OAnimation':'oAnimationEnd',
      'MozAnimation':'animationend',
      'WebkitAnimation':'webkitAnimationEnd'
    };

    for(t in transitions){
      if( el.style[t] !== undefined ){
        return transitions[t];
      }
    }
  }

  componentDidMount() {
    let logo_wrapper = document.getElementsByClassName("logo-wrapper")[0];
    if (sessionStorageItemHeader === "true") {
      window.sessionStorage.setItem("firstLook", "false");
    } else {
      logo_wrapper.classList.remove("first-look");
    }
  }

  render() {
    return (
        <div className="Logo logo-wrapper first-look">
            <div className="header-logo-container-overflow"><Link to="/"><img src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/logo-full.svg" alt="Magic"/></Link></div>
        </div>
    );
  }


}

export default Logo;
