import React from 'react';
import {Link} from 'gatsby';

const Footer = () =>
  (<footer className="footer">
    <div className="container-fluid">
      <div className="wrapper">
        <div className="row">
          <div className="col-xs-14 col-md-12 col-md-offset-1 footer-logo-container">
            <img className="footer-logo" src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/logo-full.svg" alt="magic"/>
          </div>
          <div className="col-xs-14 col-md-6 col-md-offset-1 footer-links footer-links-left">
            {/*<Link to="/people/" className="footer-link font-mono-regular uppercase">About</Link>*/}
            <Link to="/privacy/" className="footer-link font-mono-regular uppercase">Privacy Policy</Link>
            <Link to="/terms/" className="footer-link font-mono-regular uppercase">Terms of Use</Link>
          </div>
          <div className="col-xs-14 col-md-6 footer-links footer-links-right">
            <Link to="/contact/" className="footer-link font-mono-regular uppercase">Contact</Link>
            {/*eslint-disable-next-line*/}
            <a href="https://medium.com/helloitsmagic" onClick={()=>window.analytics.track('Clicked Medium Link', {component:'footer'})} target="_blank" className="footer-link font-mono-regular uppercase">Blog</a>
            {/*<a href="#" className="footer-link font-mono-regular uppercase">Instagram</a>*/}
            {/*eslint-disable-next-line*/}
            <a href="https://twitter.com/helloitsmagic" onClick={()=>window.analytics.track('Clicked Twitter Link', {component:'footer'})} target="_blank" className="footer-link font-mono-regular uppercase">Twitter</a>
          </div>
        </div>
      </div>
    </div>
  </footer>);

export default Footer;
