import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from "gatsby"
import {SEOMeta, SEOSchema} from '../SEO';
import Header from '../header/index';
import Footer from '../footer/index';

import '../../styles/main.scss';

class TemplateWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {...this.props.data.site.siteMetadata}

    // locally adjust default SEO metadata with page specific if it exists
    if(this.props.metadata) {
      for(var key in this.props.metadata) {
        this.state[key] = this.props.metadata[key]
      }
    }
  }

  render() {
    return (
      <div>
        <SEOMeta data={this.state} location={this.props.location}/>
        <SEOSchema data={this.state} location={this.props.location}/>
        <Header location={this.props.location.pathname}/>
        {this.props.children}
        <Footer />
      </div>
    )
  }
}; 

TemplateWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired,
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            name,
            title,
            description,
            url,
            imageUrl,
            logoUrl,
            twitterProfile,
            twitterProfileUrl,
            fbAppID,
            fbProfileUrl
          }
        }
      }
    `}
    render={data => <TemplateWrapper data={data} {...props}/>}
  />
);
