import React from 'react';
import Helmet from 'react-helmet';
/*eslint-disable-next-line*/
import PropTypes from 'prop-types';

const SEOMeta = ({ data, location }) => {

  return (
    <Helmet 
      defaultTitle={data.title}
    >
      <html dir="ltr" lang="en"/>

      {/* General tags */}
      <title>{data.title}</title>
      <meta name="description" content={data.description} />
      <meta name="image" content={location.origin+data.imageUrl} />
      <meta name="keywords" content={data.keywords} />

      <meta name="referrer" content="no-referrer-when-downgrade"/>
      <meta name="robots" content="all"/>
      <meta name="apple-mobile-web-app-capable" content="yes"/>
      <meta name="google-site-verification" content={data.googleSiteVer}/>

      <meta itemprop="name" content={data.title} />
      <meta itemprop="description" content={data.description} />
      <meta itemprop="image" content={location.origin+data.imageUrl} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={location.href} />
      <meta property="og:site_name" content={data.name}/>
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US"/>
      <meta property="og:title" content={data.title} />
      <meta property="og:description" content={data.description} />
      <meta property="og:image" content={location.origin+data.imageUrl} />
      <meta property="og:image:width" content="1200"/>
      <meta property="og:image:height" content="630"/>
      <meta property="fb:app_id" content={data.fbAppID} />
      <meta property="fb:profile_id" content={data.fbProfileUrl}/>

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={data.twitterProfile} />
      <meta name="twitter:creator" content={data.twitterProfile} />
      <meta name="twitter:title" content={data.title} />
      <meta name="twitter:description" content={data.description} />
      <meta name="twitter:image" content={location.origin+data.logoUrl} />
      <meta name="twitter:image:width" content="800"/>
      <meta name="twitter:image:height" content="800"/>

      {/* General links */}
      <link rel="home" href={location.origin}/>
      <link rel="canonical" href={data.url+location.pathname}/>
      <link rel="alternate" hreflang="x-default" href={data.url+location.pathname}/>
    </Helmet>
  );
};

const SEOSchema = ({ data, location }) => {
  const date = new Date();
  const webpageSchema = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify({
      "@context":"http://schema.org",
      "@type":"WebPage",
      "author": {
        "@id": "#creator"
      },
      "copyrightHolder": {
        "@id": "#creator"
      },
      "copyrightYear":date.getFullYear(),
      "creator": {
        "@id": "#creator"
      },
      "dateModified":date,
      "datePublished":"2018-10-10T01:00:00-06:00",
      "description": data.description,
      "headline": data.title,
      "inLanguage":"en-us",
      "mainEntityOfPage":location.href,
      "name":data.title,
      "publisher": {
          "@id": "#creator"
      },
    }),
  }

  const orgLogoSchema = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify({
      "@context": "http://schema.org",
      "@type": "Organization",
      "@id": "#creator",
      "url": data.url,
      "logo": location.origin + data.logoUrl
    })
  }

  // todo: add gogle+, linkedin, instagram, etc
  const orgSocialSchema = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify({
      "@context": "http://schema.org",
      "@type": "Organization",
      "name": data.name,
      "url": data.url,
      "sameAs": [
        data.fbProfileUrl,
        data.twitterProfileUrl
      ]
    })
  }

  // todo: add breadcrumb schema

  return(
    <Helmet script={[webpageSchema, orgLogoSchema, orgSocialSchema]} onChangeClientState={(newState, addedTags, removedTags) => window.analytics.page()} />
  ); 
};

// TODO: set proptypes
// SEOMeta.propTypes = {
//   postData: PropTypes.shape({
//     frontmatter: PropTypes.any,
//     excerpt: PropTypes.any,
//   }).isRequired,
//   postImage: PropTypes.string,
// };

export {SEOMeta, SEOSchema};
