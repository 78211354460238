// import React from 'react';
import React from "react";
import {Link, withPrefix} from "gatsby";
import Logo from "../Logo";

class Header extends React.Component {

// const Header = ({ location }) => {

  constructor(props) {
    super(props);
    this.state = {showModal: false};
    this.showToggleNav = this.showToggleNav.bind(this);
  }

  showToggleNav() {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  }

  mobileOpen(e) {
    let currentButton = e.currentTarget;
    currentButton.classList.toggle('open');
    currentButton.nextSibling.classList.toggle('open');
  }


  render() {

    const { location } = this.props;

    const isHomepage = location === withPrefix("/");
    const isNetworkpage = location === withPrefix("/network/");
    const isPeoplepage = location === withPrefix("/people/");
    const isExperiencespage = location === withPrefix("/experiences/");
    const isExpEnterprisepage = location === withPrefix("/experiences/enterprise/");
    const isExpProvidersepage = location === withPrefix("/experiences/providers/");
    const isTechpage = location === withPrefix("/tech/");
    const isTechCellpage = location === withPrefix("/tech/cellular/");
    const isTech5Gpage = location === withPrefix("/tech/5g/");

    let activeClass;
    if (this.state.showModal) {
      activeClass = "active"
    } else {
      activeClass = "";
    }

    const mobileNav = (
      <div className="mobileNav">
        <ul className="mobileNav-list">
          <li className="mobileNav-list-item">
            <Link
              to="/"
              className="font-medium"
            >
              {isHomepage && <span className="mobileNav-list-item-dot color-green">&#8226;</span>} <span className="mobileNav-list-item-text Heading Heading-d1 light">Hello</span>
            </Link>
          </li>
          <li className="mobileNav-list-item">
            <Link
              to="/network/"
              className="font-medium"
            >
              {isNetworkpage && <span className="mobileNav-list-item-dot color-purple">&#8226;</span>} <span className="mobileNav-list-item-text Heading Heading-d1 light">The Network</span>
            </Link>
          </li>
          {/*<li className="mobileNav-list-item has-sub">*/}
            {/*<Link*/}
              {/*to="/tech/"*/}
              {/*className="font-medium"*/}
            {/*>*/}
              {/*{isTechpage && <span className="mobileNav-list-item-dot color-green">&#8226;</span>} <span className="mobileNav-list-item-text Heading Heading-d1 light">Tech</span>*/}
            {/*</Link>*/}
            {/*<span onClick={(e) => this.mobileOpen(e)} className={"open-menu tech" + (isTechpage || isTechCellpage || isTech5Gpage ? " open" : "")}><svg width="15" height="15" viewBox="0 0 15 15"><path d="M.664.517L.517.664 7.353 7.5.517 14.336l.147.147L7.5 7.647l6.836 6.836.147-.147L7.647 7.5 14.483.664l-.147-.147L7.5 7.353z" stroke="#00F8DD" fill="none" fillRule="evenodd"/></svg></span>*/}
            {/*<ul className={"mobileNav-list-item-subnav" + (isTechpage || isTechCellpage || isTech5Gpage ? " open" : "")}>*/}
              {/*<li>*/}
                {/*<Link*/}
                  {/*to="/tech/"*/}
                  {/*className="font-medium">*/}
                  {/*<span className="mobileNav-list-item-text Heading Heading-d1 light">WiFi</span>*/}
                {/*</Link>*/}
              {/*</li>*/}
              {/*<li>*/}
                {/*<Link*/}
                  {/*to="/tech/cellular/"*/}
                  {/*className="font-medium">*/}
                  {/*<span className="mobileNav-list-item-text Heading Heading-d1 light">Cellular</span>*/}
                {/*</Link>*/}
              {/*</li>*/}
              {/*<li>*/}
                {/*<Link*/}
                  {/*to="/tech/5g/"*/}
                  {/*className="font-medium">*/}
                  {/*<span className="mobileNav-list-item-text Heading Heading-d1 light">5G</span>*/}
                {/*</Link>*/}
              {/*</li>*/}
            {/*</ul>*/}
          {/*</li>*/}
          {/*<li className="mobileNav-list-item has-sub">*/}
            {/*<Link*/}
              {/*to="/experiences/"*/}
              {/*className="font-medium">*/}
              {/*{isExperiencespage && <span className="mobileNav-list-item-dot color-pink">&#8226;</span>} <span className="mobileNav-list-item-text Heading Heading-d1 light">Experiences</span>*/}
            {/*</Link>*/}
            {/*<span onClick={(e) => this.mobileOpen(e)} className={"open-menu" + (isExperiencespage || isExpProvidersepage || isExpEnterprisepage ? " open" : "")}><svg width="15" height="15" viewBox="0 0 15 15"><path d="M.664.517L.517.664 7.353 7.5.517 14.336l.147.147L7.5 7.647l6.836 6.836.147-.147L7.647 7.5 14.483.664l-.147-.147L7.5 7.353z" stroke="#00F8DD" fill="none" fillRule="evenodd"/></svg></span>*/}
              {/*<ul className={"mobileNav-list-item-subnav" + (isExperiencespage || isExpProvidersepage || isExpEnterprisepage ? " open" : "")}>*/}
                {/*<li>*/}
                  {/*<Link*/}
                    {/*to="/experiences/"*/}
                    {/*className="font-medium">*/}
                    {/*<span className="mobileNav-list-item-text Heading Heading-d1 light">Consumer</span>*/}
                  {/*</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                  {/*<Link*/}
                    {/*to="/experiences/providers/"*/}
                    {/*className="font-medium">*/}
                    {/*<span className="mobileNav-list-item-text Heading Heading-d1 light">Providers</span>*/}
                  {/*</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                  {/*<Link*/}
                    {/*to="/experiences/enterprise/"*/}
                    {/*className="font-medium">*/}
                    {/*<span className="mobileNav-list-item-text Heading Heading-d1 light">Enterprise</span>*/}
                  {/*</Link>*/}
                {/*</li>*/}
              {/*</ul>*/}
          {/*</li>*/}
          {/*<li className="mobileNav-list-item">*/}
            {/*<Link*/}
              {/*to="/people/"*/}
              {/*className="font-medium"*/}
            {/*>*/}
              {/*{isPeoplepage && <span className="mobileNav-list-item-dot color-orange">&#8226;</span>} <span className="mobileNav-list-item-text Heading Heading-d1 light">People</span>*/}
            {/*</Link>*/}
          {/*</li>*/}
          <li className="mobileNav-list-item join-waitlist">
            <Link
              to="/waitlist/"
              className="BtnGhost BtnGhost-large purple uppercase color-white"
              onClick={()=>window.analytics.track('Clicked Waitlist CTA',{component:'mobileNav'})}
            >
              <span>Sign up now</span>
            </Link>
          </li>
        </ul>
      </div>
    );

    return (
      <header className="header">
        <div className="container-fluid">
          <div className="wrapper header-wrapper">
            <div className="row header-content">
              <div className="col-xs-7 col-lg-2 col-lg-offset-1 header-logo-container">
                {isHomepage ?
                  <Logo /> :
                  <div className="header-logo-container-overflow"><Link to="/"><img src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/logo-full.svg" alt="Magic"/></Link></div>
                }
              </div>
              <div className="col-xs-7 col-lg-10">
                <nav className="header-nav">
                  <ul className="header-nav-list">
                    <li className="header-nav-list-item">
                      {isHomepage ?
                        <Link
                          to="/"
                          activeStyle={{
                            color: '#00F8DD',
                            fontWeight: 600
                          }}
                          className="font-mono-regular uppercase"
                        >
                          <span className="header-nav-list-item-text">Hello</span>
                          {isHomepage && <span className="header-nav-list-item-dot">&#8226;</span>}
                        </Link> :
                        <Link
                          to="/"
                          activeStyle={{
                            color: '#CFCFCF'
                          }}
                          className="font-mono-regular uppercase"
                        >
                          <span className="header-nav-list-item-text">Hello</span>
                        </Link>
                      }
                    </li>
                    <li className="header-nav-list-item">
                      <Link
                        to="/network/"
                        activeStyle={{
                          color: '#9013FE',
                          fontWeight: 600
                        }}
                        className="font-mono-regular uppercase"
                      >
                        <span className="header-nav-list-item-text">The Network</span>
                        {isNetworkpage && <span className="header-nav-list-item-dot">&#8226;</span>}
                      </Link>
                    </li>

                    {/*<li className={"header-nav-list-item" + ((isExperiencespage || isExpEnterprisepage || isExpProvidersepage) ? " active pink" : "")}>*/}
                    {/*  <Link*/}
                    {/*    to="/experiences/"*/}
                    {/*    activeStyle={{*/}
                    {/*      color: '#FF3BDB',*/}
                    {/*      fontWeight: 600*/}
                    {/*    }}*/}
                    {/*    className="font-mono-regular uppercase"*/}
                    {/*  >*/}
                    {/*    <span className="header-nav-list-item-text">Experiences</span>*/}
                    {/*    {(isExperiencespage || isExpProvidersepage || isExpEnterprisepage) && <span className="header-nav-list-item-dot">&#8226;</span>}*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li className={"header-nav-list-item" + ((isTechpage || isTechCellpage || isTech5Gpage) ? " active green" : "")}>*/}
                    {/*  <Link*/}
                    {/*    to="/tech/"*/}
                    {/*    activeStyle={{*/}
                    {/*      color: '#00F8DD',*/}
                    {/*      fontWeight: 600*/}
                    {/*    }}*/}
                    {/*    className="font-mono-regular uppercase"*/}
                    {/*  >*/}
                    {/*    <span className="header-nav-list-item-text">Tech</span>*/}
                    {/*    {(isTechpage || isTechCellpage || isTech5Gpage) && <span className="header-nav-list-item-dot">&#8226;</span>}*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li className="header-nav-list-item">*/}
                    {/*  <Link*/}
                    {/*    to="/people/"*/}
                    {/*    activeStyle={{*/}
                    {/*      color: '#FF4700',*/}
                    {/*      fontWeight: 600*/}
                    {/*    }}*/}
                    {/*    className="font-mono-regular uppercase"*/}
                    {/*  >*/}
                    {/*    <span className="header-nav-list-item-text">People</span>*/}
                    {/*    {isPeoplepage && <span className="header-nav-list-item-dot">&#8226;</span>}*/}
                    {/*  </Link>*/}
                    {/*</li>*/}

                    <li className="header-nav-list-item blog">
                      {/*eslint-disable-next-line*/}
                      <a href="https://medium.com/helloitsmagic" className="font-mono-regular uppercase" target="_blank" rel="noopener">
                        <span className="header-nav-list-item-text">Blog</span>
                      </a>
                    </li>
                    <li className="header-nav-list-item waitlist">
                      <Link
                        to="/waitlist/"
                        className="BtnGhost BtnGhost-large purple uppercase"
                        onClick={()=>window.analytics.track('Clicked Waitlist CTA',{component:'nav'})}
                      >
                        <span className="header-nav-list-item-text">Sign up now</span>
                      </Link>
                    </li>
                  </ul>
                </nav>

                <div className={`button_container ${activeClass}`} id="toggle" onClick={()=> {this.showToggleNav()}}>
                  <span className="top"></span>
                  <span className="bottom"></span>
                </div>
                {this.state.showModal ? mobileNav : ''}

              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }


}

export default Header;
